<template>
    <div class="withdrawalrecord">
        <!-- 表格start -->
        <tablelist
          :tableArray="tableArray"
          :defaultTable="defaultTable"
          ref="table"
          @currentMethod="currentMethod"
          :isPage="true"
          :total="total"
          :pageSize="params.page_size"
          :currentPage="params.page_index"
        ></tablelist>
        <!-- 表格end -->
      </div>
</template>
<script>
    import {nowDataMethod} from '../../utils/index'
    import api from "../../http/api";
    export default {
      components: {
        tablelist: () => import("../components/table"),
      },
      data() {
        return {
          params: {
            start_time: nowDataMethod(),
            page_index: 1,
            page_size: 40,
          },
          loading: false,
          total: 0,
          tableH: 0,
          tableArray: [
          {
              key: "group_type_desc",
              name: "群类型",
              width: "30px",
            },
            {
              key: "name",
              name: "群名称",
            },
            {
              key: "group_status_desc",
              name: "群状态",
              width: "30px",
            },
          
            {
              key: "create_time",
              name: "上传日期",
            },
            {
              key: "expiry_date",
              name: "到期日期",
              width: "100px",
            },
            {
              key: "id",
              name: "编号",
              width: "100px",
            },
            {
              key: "msg",
              name: "描述",
            },
           
          ],
          totalCount:'',//总量
          defaultTable: [],
        };
      },
      activated(){
        this.getList();
      },

     methods: {
        currentMethod(index) {
      this.getList(index);
    },
        //获取列表数据
        async getList(data) {
          let that = this;
          if (data) that.params.page_index = data;
          let result = await api.getTodayUpload(that.params);
          if (result.code == 200) {
            let res = result.response;
            that.total = res.total;
            that.totalCount=that.total-(that.params.page_index-1)*that.params.page_size
           if( res && res.data.length != 0){
               res.data.forEach(item=>{
                item.id=`第${item.id}_${that.totalCount}`;
                that.totalCount--;
                console.log('len',that.totalCount)
                 //(that.totalCount/that.params.page_index)--
               }) 
               that.defaultTable= res.data
            }else{
              that.defaultTable=[];
            }
          }
        },
      },
    };
    </script>
    <style lang="less">
    .withdrawalrecord {
      width: 100%;
      height: 100%;
    }
    </style>